import { ref } from 'vue'
import Sortable from 'sortablejs'

// 排序相关
export const tableRef = ref(null)

/**
 * 初始化排序
 */
export const initSortable = () => {
  const el = tableRef.value.$el.querySelector('.el-table__body > tbody')

  // 1. 要拖拽的元素
  // 2. 配置对象
  Sortable.create(el, {
    // 拖拽时类名
    ghostClass: 'sortable-ghost',
    // 拖拽结束的回调方法
    onEnd(event) {}
  })
}
