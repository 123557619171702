import request from '@/utils/request'

// 轮播列表
export const rotationListAPI = (params) => {
  return request({
    url: '/bannerlist',
    method: 'GET',
    params
  })
}

// 上传图片
export const UPLOAD_PICTURE = process.env.VUE_APP_BASE_API + '/upload'

// 添加
export const addRotationAPI = (data) => {
  return request({
    url: '/addBanner',
    method: 'POST',
    data
  })
}

export const editMotorAPI = (data) => {
  return request({
    url: '/bannerSave',
    method: 'POST',
    data
  })
}

// 列表删除
export const listDeleteAPI = (params) => {
  return request({
    url: '/bannerDel',
    method: 'GET',
    params
  })
}
